@media print {
  body {
    margin: 0;
    margin-bottom: 100px;
  }
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

@page {
  margin-top: 1rem;
}

a {
  color: rgb(35, 55, 108);
  text-decoration: underline;
}

#wrapper {
  position: fixed;
  overflow: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 3px solid #1e336d;
  page-break-after: always;
}

.bodydiv {
  size: a4;
  position: relative;
  width: 210mm;
  height: 270mm;
  padding: 1rem 0rem 5rem 0rem;
  margin: 0 auto;
  color: #001028;
  background: #ffffff;
  font-family: Arial, sans-serif;
  font-size: 12px;
  font-family: Arial;
  /* page-break-inside: auto; */
}

#logo {
  text-align: center;
}

#logo img {
  width: 100%;
}

.title {
  color: rgb(35, 55, 108);
  font-size: 2em;
  line-height: 1.4em;
  font-weight: normal;
  text-align: left;
  margin: 0 0 10px 0;
  padding-left: 60px;
}

.borderTop {
  border-top: 1px solid rgb(35, 55, 108);
  padding: 5px;
}

#project {
  float: left;
}

#project span {
  color: rgb(35, 55, 108);
  text-align: right;
  width: 52px;
  margin-right: 10px;
  display: inline-block;
  font-size: 0.8em;
}

.quote-date {
  width: 160px;
  float: right;
  padding-top: 15px;
  padding-right: 20px;
  color: #555555;
}
.address-to {
  width: 200px;
  float: left;
  padding: 20px;
  padding-top: 15px;
}
.address-to h3 {
  margin: 0;
  color: #555555;
}

#company {
  float: right;
  text-align: right;
}

#project div,
#company div {
  white-space: nowrap;
}
.coverImg {
  margin-top: 10px;
  margin-left: 10px;
  max-height: 200px;
  max-width: 200px;
}

#notices {
  padding-top: 1rem;
  page-break-inside: avoid;
  page-break-after: auto;
  padding-left: 20px;
}
#po {
  padding-left: 20px;
  font-size: 1em;
}
#notices .notice {
  color: #555555;
  max-width: 600px;
}

.footerDiv {
  display: none;
  padding: 0px 0;
  text-align: center;
  bottom: 0;
}

@media print {
  .footerDiv {
    display: block;
    width: 95%;
    position: fixed;
    bottom: 7px;
    left: 10px;
  }
}

.infoDiv {
  border: 1px solid #000000;
  padding: 5px;
  page-break-inside: avoid;
  page-break-after: auto;
}
